import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { Element } from 'react-scroll';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// Custom components.

// import ExploreMedia from '../../components/Home/ExploreMedia';

// Libraries.

import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

// Assets.

import exploreBanner from '../../assets/images/banner-explore@2x.png';

const sectionName = 'section-explore';

const plyrOptions = {
  controls: ['play-large', 'play', 'progress', 'current-time', 'mute', null, 'captions', null, 'pip', null, 'fullscreen'],
  youtube: { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 },
};

class Explore extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
  }

  handleSectionOnClick = () => {
    const { height } = this.state;

    this.setState({
      height: height === 0 ? 'auto' : 0,
    });

    this.props.handleScrollToElementOnChange(sectionName);
  };

  render() {
    const { exploreHeight } = this.props;

    let sectionOpenedClass = exploreHeight === 0 ? '' : 'section-toggled';

    return (
      <section className={`section-explore ${sectionOpenedClass}`}>
        <Element name={sectionName} className="section-tab">
          <a
            onClick={ () => this.props.handleSectionOnClick(sectionName) }
          >
            Explore
            <span className="section-tab-toggle"></span>
          </a>
        </Element>

        <AnimateHeight
          duration={ 500 }
          height={ exploreHeight }
          easing="ease-out"
          className="section-content"
          contentClassName="section-content-inner"
        >

          {/* Bubble */}
          {/* <div className="wkw-speech-bubble2">
            <p className="wkw-flex">
              <span>Most media isn’t made in classrooms, so WKWSCI’s learning isn’t either. WKWSCI offers practicums for our students to apply what they’ve learnt in an industry-setting, or in overseas experiences.</span>
            </p>
          </div> */}
          {/* End: Bubble */}

          {/* Banner */}
          <div className="wkw-banner">
            {/* <a
              href="https://www.ntu.edu.sg/wkwsci/admissions/experiential-learning"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={exploreBanner} alt="" />
            </a> */}

            {/* Download Box */}
            {/* <div className="wkw-more-info-box">
              <a
                href="https://www.ntu.edu.sg/wkwsci/admissions/experiential-learning"
                target="_blank"
                rel="noopener noreferrer"
              >
                More Information
              </a>
            </div> */}
            {/* End: Download Box */}

            <img src={exploreBanner} alt="" />

            {/* Download Box */}
            <div className="wkw-more-info-box">
              <p>Most media isn’t made in classrooms, so WKWSCI’s learning isn’t either. WKWSCI offers practicums for our students to apply what they’ve learnt in an industry-setting, or in overseas experiences.</p>
              <a
                href="https://www.ntu.edu.sg/wkwsci/admissions/experiential-learning"
                target="_blank"
                rel="noopener noreferrer"
              >
                More Information
              </a>
            </div>
            {/* End: Download Box */}
          </div>
          {/* End: Banner */}

          {/* Explore Box */}
          <div className="wkw-explore-box">
            <div className="wkw-explore-box-inner">

              {/* Video Box */}
              <div className="wkw-video-box">
                <div className="wkw-video wkw-video-plyr">
                  <Plyr
                    source={{
                      type: "video",
                      sources: [{ src: "9Q2ZEAqkR_Q", provider: "youtube" }],
                    }}
                    options={plyrOptions}
                  />
                </div>

                <h3 className="wkw-video-box_title">
                  #WhyWKWSCI: The WKWSCI Experience
                </h3>
              </div>
              {/* End: Video Box */}

              {/* Video Box */}
              <div className="wkw-video-box">
                <div className="wkw-video wkw-video-plyr">
                  <Plyr
                    source={{
                      type: "video",
                      sources: [{ src: "0pInVwEkQkg", provider: "youtube" }],
                    }}
                    options={plyrOptions}
                  />
                </div>

                <h3 className="wkw-video-box_title">
                  #WhyWKWSCI: WeeCan (Student Life)
                </h3>
              </div>
              {/* End: Video Box */}

              {/* Video Box */}
              <div className="wkw-video-box">
                <div className="wkw-video wkw-video-plyr">
                  <Plyr
                    source={{
                      type: "video",
                      sources: [{ src: "KXfXON0pQ3o", provider: "youtube" }],
                    }}
                    options={plyrOptions}
                  />
                </div>

                <h3 className="wkw-video-box_title">
                  A Day in the Life of a Weekid
                </h3>
              </div>
              {/* End: Video Box */}

              {/* Video Box */}
              <div className="wkw-video-box">
                {/* <ExploreMedia plyrOptions={plyrOptions} /> */}
                <div className="wkw-video wkw-video-plyr">
                  <Plyr
                    source={{
                      type: "video",
                      sources: [{ src: "UH6gKk2S6iI", provider: "youtube" }],
                    }}
                    options={plyrOptions}
                  />
                </div>

                <h3 className="wkw-video-box_title">
                  A Glimpse into Broadcast Media & Media Studies at WKWSCI
                </h3>
              </div>
              {/* End: Video Box */}

              {/* Video Box */}
              <div className="wkw-video-box-container">
                <Carousel
                  renderIndicator={false}
                  showArrows={true}
                  showThumbs={false}
                >

                  {/* Video Box */}
                  <div className="wkw-video-box">
                    <div className="wkw-video wkw-video-plyr">
                      <Plyr
                        source={{
                          type: "video",
                          sources: [{ src: "vdokHpj9YCk", provider: "youtube" }],
                        }}
                        options={plyrOptions}
                      />
                    </div>

                    <h3 className="wkw-video-box_title">
                      Behind The Scenes of a student film production - Ep 1
                    </h3>
                  </div>
                  {/* End: Video Box */}

                  {/* Video Box */}
                  <div className="wkw-video-box">
                    <div className="wkw-video wkw-video-plyr">
                      <Plyr
                        source={{
                          type: "video",
                          sources: [{ src: "J9GpIblQX9Y", provider: "youtube" }],
                        }}
                        options={plyrOptions}
                      />
                    </div>

                    <h3 className="wkw-video-box_title">
                      Behind The Scenes of a student film production - Ep 2
                    </h3>
                  </div>
                  {/* End: Video Box */}

                  {/* Video Box */}
                  <div className="wkw-video-box">
                    <div className="wkw-video wkw-video-plyr">
                      <Plyr
                        source={{
                          type: "video",
                          sources: [{ src: "877e654u8vo", provider: "youtube" }],
                        }}
                        options={plyrOptions}
                      />
                    </div>

                    <h3 className="wkw-video-box_title">
                      Behind The Scenes of a student film production - Ep 3
                    </h3>
                  </div>
                  {/* End: Video Box */}

                </Carousel>
              </div>
              {/* End: Video Box */}

            </div>
          </div>
          {/* End: Explore Box */}
        </AnimateHeight>
      </section>
    );
  }

}

export default Explore;
