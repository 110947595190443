import React from 'react';

function Footer() {
  return (
    <footer className="site-footer">
      <div className="site-footer-inner">

        {/* Footer Left */}
        <div className="site-footer_col site-footer_left">
          <p>Wee Kim Wee School of Communication and Information</p>
          <p>31 Nanyang Link, Singapore 637718</p>
        </div>
        {/* End: Footer Left */}

        {/* Footer Right */}
        <div className="site-footer_col site-footer_right">
          <p>Follow us on <a href="https://instagram.com/wkwsci" target="_blank" rel="noopener noreferrer">Instagram</a> & <a href="https://www.facebook.com/wkwsci" target="_blank" rel="noopener noreferrer">Facebook</a> </p>
          <p>Visit our website: <a href="http://www.ntu.edu.sg/wkwsci" target="_blank" rel="noopener noreferrer">www.ntu.edu.sg/wkwsci</a></p>
          <p>All Rights Reserved © NTU WKWSCI Open House 2024</p>
        </div>
        {/* End: Footer Right */}

      </div>
    </footer>
  );
}

export default Footer;
