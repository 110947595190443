import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { Element } from 'react-scroll';

// Libraries.

import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

// Assets.

// import bannerWhy from '../../assets/images/banner-why.png';
import iconTopLeft from '../../assets/images/why-icon-top-left@2x.png';
import iconBottomRight from '../../assets/images/why-icon-bottom-right@2x.png';

import iconTrophy from '../../assets/images/icon-trophy@2x.png';
import iconBalance from '../../assets/images/icon-balance@2x.png';
import iconShakeHands from '../../assets/images/icon-shake-hands@2x.png';
import iconClassroom from '../../assets/images/icon-classroom@2x.png';
import iconGlobe from '../../assets/images/icon-globe@2x.png';
import iconNetwork from '../../assets/images/icon-network@2x.png';
import iconPeople from '../../assets/images/icon-people@2x.png';
import iconBuilding from '../../assets/images/icon-building@2x.png';

import iconBroadcast from '../../assets/images/icon-broadcast@2x.png';
import iconAnalytics from '../../assets/images/icon-analytics@2x.png';
import iconJournalism from '../../assets/images/icon-journalism@2x.png';
import iconPR from '../../assets/images/icon-pr@2x.png';

const sectionName = 'section-why';

const plyrOptions = {
  controls: ['play-large', 'play', 'progress', 'current-time', 'mute', null, 'captions', null, 'pip', null, 'fullscreen'],
  youtube: { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 },
};

class Why extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
  }

  handleSectionOnClick = () => {
    const { height } = this.state;

    this.setState({
      height: height === 0 ? 'auto' : 0,
    });

    this.props.handleScrollToElementOnChange(sectionName);
  };

  render() {
    const { whyHeight } = this.props;

    let sectionOpenedClass = whyHeight === 0 ? '' : 'section-toggled';

    return (
      <section className={`section-why ${sectionOpenedClass}`}>
        <Element name={sectionName} className="section-tab">
          <a
            onClick={ () => this.props.handleSectionOnClick(sectionName) }
          >
            Why WKWSCI?
            <span className="section-tab-toggle"></span>
          </a>
        </Element>

        <AnimateHeight
          height={ whyHeight }
          duration={ 500 }
          delay={120}
          easing="ease-out"
          className="section-content"
          contentClassName="section-content-inner"
        >
          {/* Floating Objects */}
          <div className="section-why-video-objects">
            <div className="section-why-video-object1">
              <img src={iconTopLeft} alt="" />
            </div>
            <div className="section-why-video-object2">
              <img src={iconBottomRight} alt="" />
            </div>
          </div>
          {/* End: Floating Objects */}

          {/* Why Box */}
          <div className="wkw-why-box">
            {/* <img src={bannerWhy} alt="" /> */}

            {/* Video Box */}
            <div className="wkw-video-box">
              <div className="wkw-video wkw-video-plyr">
                <Plyr
                  source={{
                    type: "video",
                    sources: [{ src: "gVNaWqWUecU", provider: "youtube" }],
                  }}
                  options={plyrOptions}
                />
              </div>
            </div>
            {/* End: Video Box */}

          </div>
          {/* End: Why Box */}

          <div className="section-why_reasons why-reasons">
            

            <div className="section-why_reasons_content">
              <h3 className="section-why_reasons_title">
                8 Reasons to Join WKWSCI
              </h3>

              {/* Column */}
              <div className="section-why_reasons_column column1 bt-0-mobile">

                {/* Column - Row */}
                <div className="section-why_reasons_row">
                  <div className="section-why_reasons_row_icon">
                    <img src={iconTrophy} alt="" />
                  </div>

                  <div className="section-why_reasons_row_content">
                    <div className="section-why_reasons_row_subtitle">
                      <a
                        href="https://www.ntu.edu.sg/wkwsci/admissions/international-standing"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        International Standing
                      </a>
                    </div>
                    <div className="section-why_reasons_row_description">
                      Communication & Media Studies<br />
                      <span className="text-red">#1</span> in Asia, <span className="text-red">#6</span> in the World<br />
                      (QS World University Ranking by Subject 2023)
                    </div>
                  </div>
                </div>
                {/* End: Column - Row */}

                {/* Column - Row */}
                <div className="section-why_reasons_row">
                  <div className="section-why_reasons_row_icon">
                    <img src={iconBalance} alt="" />
                  </div>

                  <div className="section-why_reasons_row_content">
                    <div className="section-why_reasons_row_subtitle">
                      <a
                        href="https://www.ntu.edu.sg/wkwsci/admissions/programmes"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Well-Balanced Curriculum
                      </a>
                    </div>
                    <div className="section-why_reasons_row_description">
                      Covers both traditional and new media content, with a strong balance in both practical and conceptual skills
                    </div>
                  </div>
                </div>
                {/* End: Column - Row */}

                {/* Column - Row */}
                <div className="section-why_reasons_row">
                  <div className="section-why_reasons_row_icon icon-small1">
                    <img src={iconShakeHands} alt="" />
                  </div>

                  <div className="section-why_reasons_row_content valign-center">
                    <div className="section-why_reasons_row_subtitle">
                      <a
                        href="https://www.ntu.edu.sg/wkwsci/admissions/industry-links-partnerships"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Industry Links & Partnerships
                      </a>
                    </div>
                    <div className="section-why_reasons_row_description">
                      Gain cutting-edge industrial and technical know-how, valuable insights and networking opportunities through various courses conducted by partners across a wide range of industries
                    </div>
                  </div>
                </div>
                {/* End: Column - Row */}

                {/* Column - Row */}
                <div className="section-why_reasons_row">
                  <div className="section-why_reasons_row_icon">
                    <img src={iconClassroom} alt="" />
                  </div>

                  <div className="section-why_reasons_row_content valign-center">
                    <div className="section-why_reasons_row_subtitle">
                      <a
                        href="https://www.ntu.edu.sg/wkwsci/about-us/our-people"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Renowned Faculty
                      </a>
                    </div>
                    <div className="section-why_reasons_row_description">
                      Taught by a combination of leading academics and practitioners in the field, aligning closely with innovation taking place in the industry
                    </div>
                  </div>
                </div>
                {/* End: Column - Row */}

              </div>
              {/* End: Column */}

              {/* Column */}
              <div className="section-why_reasons_column column2">

                {/* Column - Row */}
                <div className="section-why_reasons_row">
                  <div className="section-why_reasons_row_icon">
                    <img src={iconGlobe} alt="" />
                  </div>

                  <div className="section-why_reasons_row_content">
                    <div className="section-why_reasons_row_subtitle">
                      <a
                        href="https://www.ntu.edu.sg/wkwsci/admissions/experiential-learning"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Experiential Learning
                      </a>
                    </div>
                    <div className="section-why_reasons_row_description">
                      Practice-based experiential and contextual real-world learning to apply what you’ve learnt in an industry setting, or in overseas experiences
                    </div>
                  </div>
                </div>
                {/* End: Column - Row */}

                {/* Column - Row */}
                <div className="section-why_reasons_row">
                  <div className="section-why_reasons_row_icon">
                    <img src={iconNetwork} alt="" />
                  </div>

                  <div className="section-why_reasons_row_content valign-center">
                    <div className="section-why_reasons_row_subtitle">
                      <a
                        href="https://www.ntu.edu.sg/wkwsci/alumni"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Strong Alumni Network
                      </a>
                    </div>
                    <div className="section-why_reasons_row_description">
                      Our Alumni works closely with the School to transform the future of its programmes and curriculum
                    </div>
                  </div>
                </div>
                {/* End: Column - Row */}

                {/* Column - Row */}
                <div className="section-why_reasons_row">
                  <div className="section-why_reasons_row_icon">
                    <img src={iconPeople} alt="" />
                  </div>

                  <div className="section-why_reasons_row_content valign-center">
                    <div className="section-why_reasons_row_subtitle">
                      <a
                        href="https://www.ntu.edu.sg/wkwsci/student-life"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Vibrant Student Life
                      </a>
                    </div>
                    <div className="section-why_reasons_row_description">
                      With an extraordinary range of opportunities, students can explore student life through one of our many social events, student organisations and even engage in hands-on learning experiences
                    </div>
                  </div>
                </div>
                {/* End: Column - Row */}

                {/* Column - Row */}
                <div className="section-why_reasons_row">
                  <div className="section-why_reasons_row_icon">
                    <img src={iconBuilding} alt="" />
                  </div>

                  <div className="section-why_reasons_row_content valign-center">
                    <div className="section-why_reasons_row_subtitle">
                      <a
                        href="https://www.ntu.edu.sg/wkwsci/about-us/our-facilities"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Cutting-edge Media Production Suites and State-of-the-art Research Laboratories
                      </a>
                    </div>
                    <div className="section-why_reasons_row_description"></div>
                  </div>
                </div>
                {/* End: Column - Row */}

              </div>
              {/* End: Column */}
            </div>
          </div>

          <div className="section-why_reasons">
            <div className="section-why_reasons_content">

              {/* Column */}
              <div className="section-why_reasons_column column1 no-pad">
                <h3 className="section-why_reasons_title">
                  4 Tracks In WKWSCI
                </h3>

                <div className="section-why_reasons_content-inner">
                  {/* Column - Row */}
                  <div className="section-why_reasons_row mt">
                    <div className="section-why_reasons_row_content">
                      <div className="section-why_reasons_row_subtitle"></div>
                      <div className="section-why_reasons_row_description align-center0">
                        At the Wee Kim Wee School of Communication and Information, our curriculum allows students the flexibility to pursue courses across the different tracks. They are hence equipped for a full range of careers.
                      </div>
                    </div>
                  </div>
                  {/* End: Column - Row */}

                  {/* Column - Row */}
                  <div className="section-why_reasons_row section-why_reasons_row_small0">
                    <div className="section-why_reasons_row_icon">
                      <img src={iconBroadcast} alt="" />
                    </div>

                    <div className="section-why_reasons_row_content valign-center">
                      <div className="section-why_reasons_row_subtitle">Broadcast Media and Cinema Studies</div>
                      <div className="section-why_reasons_row_description"></div>
                    </div>
                  </div>
                  {/* End: Column - Row */}

                  {/* Column - Row */}
                  <div className="section-why_reasons_row section-why_reasons_row_small0">
                    <div className="section-why_reasons_row_icon">
                      <img src={iconAnalytics} alt="" />
                    </div>

                    <div className="section-why_reasons_row_content valign-center">
                      <div className="section-why_reasons_row_subtitle">Data Science and Media Studies</div>
                      <div className="section-why_reasons_row_description"></div>
                    </div>
                  </div>
                  {/* End: Column - Row */}

                  {/* Column - Row */}
                  <div className="section-why_reasons_row section-why_reasons_row_small0 section-why_reasons_row_offset10">
                    <div className="section-why_reasons_row_icon">
                      <img src={iconJournalism} alt="" />
                    </div>

                    <div className="section-why_reasons_row_content valign-center">
                      <div className="section-why_reasons_row_subtitle">Journalism and Media Policies</div>
                      <div className="section-why_reasons_row_description"></div>
                    </div>
                  </div>
                  {/* End: Column - Row */}

                  {/* Column - Row */}
                  <div className="section-why_reasons_row section-why_reasons_row_small0">
                    <div className="section-why_reasons_row_icon icon-small2">
                      <img src={iconPR} alt="" />
                    </div>

                    <div className="section-why_reasons_row_content valign-center">
                      <div className="section-why_reasons_row_subtitle">Strategic Communication for the Digital Age</div>
                      <div className="section-why_reasons_row_description"></div>
                    </div>
                  </div>
                  {/* End: Column - Row */}
                </div>

              </div>
              {/* End: Column */}

              {/* Column */}
              <div className="section-why_reasons_column column2 no-pad">
                <h3 className="section-why_reasons_title">
                  Graduate Employment<br />Survey 2022
                </h3>

                <div className="section-why_reasons_content-inner">
                  {/* Column - Row */}
                  <div className="section-why_reasons_row mt">
                    <div className="section-why_reasons_row_content valign-center">
                      <div className="section-why_reasons_row_title">At WKWSCI</div>
                      <div className="section-why_reasons_row_description"></div>
                    </div>
                  </div>
                  {/* End: Column - Row */}

                  {/* Column - Row */}
                  <div className="section-why_reasons_row">
                    <div className="section-why_reasons_row_content valign-center">
                      <div className="section-why_reasons_row_subtitle"></div>
                      <div className="section-why_reasons_row_description">
                        Overall Employment Rate - <span className="text-blue">94.2%</span>
                      </div>
                    </div>
                  </div>
                  {/* End: Column - Row */}

                  {/* Column - Row */}
                  <div className="section-why_reasons_row">
                    <div className="section-why_reasons_row_content valign-center">
                      <div className="section-why_reasons_row_subtitle"></div>
                      <div className="section-why_reasons_row_description">
                        Mean Gross Monthly Salary - <span className="text-blue">$3,739</span>
                      </div>
                    </div>
                  </div>
                  {/* End: Column - Row */}

                  {/* Column - Row */}
                  <div className="section-why_reasons_row">
                    <div className="section-why_reasons_row_content valign-center">
                      <div className="section-why_reasons_row_subtitle"></div>
                      <div className="section-why_reasons_row_description">
                        Median Gross Monthly Salary - <span className="text-blue">$3,500</span>
                      </div>
                    </div>
                  </div>
                  {/* End: Column - Row */}
                </div>

              </div>
              {/* End: Column */}

            </div>
          </div>

          <div className="section-why_reasons">
            <div className="section-why_reasons_content">

              {/* Video Box */}
              <div className="wkw-video-box">
                <div className="wkw-video wkw-video-plyr">
                  <Plyr
                    source={{
                      type: "video",
                      sources: [{ src: "J6Fzhe_PzQ0", provider: "youtube" }],
                    }}
                    options={plyrOptions}
                  />
                </div>
              </div>
              {/* End: Video Box */}

            </div>
          </div>

        </AnimateHeight>
      </section>
    );
  }

}

export default Why;
