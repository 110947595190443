// import logo from './logo.svg';
import './App.css';

import React, { Component } from 'react';
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

// Custom components.

import Landing from './components/Home/Landing';
import Why from './components/Home/Why';
import Overview from './components/Home/Overview';
import Explore from './components/Home/Explore';
import Tour from './components/Home/Tour';
import Connect from './components/Home/Connect';
import Footer from './components/Common/Footer';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      scrollToElement: null,

      whyHeight: 0,
      overviewHeight: 0,
      exploreHeight: 0,
      tourHeight: 0,
      connectHeight: 0,
    };
  }

  handleScrollToElementOnChange = (elementName) => {
    this.setState({
      scrollToElement: elementName,
    });

    this.scrollToElement(elementName);
  }

  scrollToElement = (elementName) => {
    scroller.scrollTo(elementName, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    })
  }

  handleSectionOnClick = (sectionName, isMenu) => {
    const { whyHeight, overviewHeight, exploreHeight, tourHeight, connectHeight } = this.state;

    let newWhyHeight = whyHeight;
    let newOverviewHeight = overviewHeight;
    let newExploreHeight = exploreHeight;
    let newTourHeight = tourHeight;
    let newConnectHeight = connectHeight;

    if (sectionName === 'section-why') {
      newWhyHeight = newWhyHeight === 0 ? 'auto' : 0;
      if (isMenu) {
        newWhyHeight = 'auto';
      }
    } else if (sectionName === 'section-overview') {
      newOverviewHeight = newOverviewHeight === 0 ? 'auto' : 0;
      if (isMenu) {
        newOverviewHeight = 'auto';
      }
    } else if (sectionName === 'section-explore') {
      newExploreHeight = newExploreHeight === 0 ? 'auto' : 0;
      if (isMenu) {
        newExploreHeight = 'auto';
      }
    } else if (sectionName === 'section-tour') {
      newTourHeight = newTourHeight === 0 ? 'auto' : 0;
      if (isMenu) {
        newTourHeight = 'auto';
      }
    } else if (sectionName === 'section-connect') {
      newConnectHeight = newConnectHeight === 0 ? 'auto' : 0;
      if (isMenu) {
        newConnectHeight = 'auto';
      }
    }

    this.setState({
      whyHeight: newWhyHeight,
      overviewHeight: newOverviewHeight,
      exploreHeight: newExploreHeight,
      tourHeight: newTourHeight,
      connectHeight: newConnectHeight,
    });

    this.handleScrollToElementOnChange(sectionName);
  };

  render() {
    const { whyHeight, overviewHeight, exploreHeight, tourHeight, connectHeight } = this.state;

    return (
      <div className="App">
        {/* <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header> */}

        <Landing handleSectionOnClick={this.handleSectionOnClick} />
        <Why handleSectionOnClick={this.handleSectionOnClick} whyHeight={whyHeight} />
        <Overview handleSectionOnClick={this.handleSectionOnClick} overviewHeight={overviewHeight} />
        <Explore handleSectionOnClick={this.handleSectionOnClick} exploreHeight={exploreHeight} />
        <Tour handleSectionOnClick={this.handleSectionOnClick} tourHeight={tourHeight} />
        <Connect handleSectionOnClick={this.handleSectionOnClick} connectHeight={connectHeight} />
        <Footer />
      </div>
    );
  }

}

export default App;
