import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { Element } from 'react-scroll';

// Assets.

import iconTopLeft from '../../assets/images/connect-icon-top-left@2x.png';
import iconInstagram from '../../assets/images/icon-instagram@2x.png';
import iconFacebook from '../../assets/images/icon-facebook@2x.png';
import iconTiktok from '../../assets/images/icon-tiktok@2x.png';
import iconYoutube from '../../assets/images/icon-youtube@2x.png';
import iconTwitter from '../../assets/images/icon-twitter@2x.png';

const sectionName = 'section-connect';

class Connect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
  }

  handleSectionOnClick = () => {
    const { height } = this.state;

    this.setState({
      height: height === 0 ? 'auto' : 0,
    });

    this.props.handleScrollToElementOnChange(sectionName);
  };

  render() {
    const { connectHeight } = this.props;

    let sectionOpenedClass = connectHeight === 0 ? '' : 'section-toggled';

    return (
      <section className={`section-connect ${sectionOpenedClass}`}>
        <Element name={sectionName} className="section-tab">
          <a
            onClick={ () => this.props.handleSectionOnClick(sectionName) }
          >
            Get Connected
            <span className="section-tab-toggle"></span>
          </a>
        </Element>

        <AnimateHeight
          duration={ 500 }
          height={ connectHeight }
          easing="ease-out"
          className="section-content"
          contentClassName="section-content-inner"
        >
          {/* Floating Objects */}
          <div className="section-connect-objects">
            <div className="section-connect-object1">
              <img src={iconTopLeft} alt="" />
            </div>
          </div>
          {/* End: Floating Objects */}

          {/* Connect Box */}
          <div className="wkw-connect-box">
            <h3 className="wkw-connect-box_title">
              Via Social Media
            </h3>

            <div className="wkw-connect-box_content">

              {/* Social Box */}
              <div className="wkw-social-box">
                <div className="wkw-social-box-links">

                <a
                  href="https://instagram.com/wkwsci"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wkw-social-box_instagram"
                >
                  <img src={iconInstagram} alt="Instagram" />
                </a>

                <a
                  href="https://www.facebook.com/wkwsci"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wkw-social-box_facebook"
                >
                  <img src={iconFacebook} alt="Facebook" />
                </a>

                <a
                  href="https://www.tiktok.com/@wkwsci"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wkw-social-box_tiktok"
                >
                  <img src={iconTiktok} alt="Tiktok" />
                </a>

                <a
                  href="https://www.youtube.com/WKWschool"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wkw-social-box_youtube"
                >
                  <img src={iconYoutube} alt="Youtube" />
                </a>

                <a
                  href="https://twitter.com/WKWschool"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wkw-social-box_twitter"
                >
                  <img src={iconTwitter} alt="Twitter" />
                </a>

                </div>
              </div>
              {/* End: Social Box */}

            </div>
          </div>
          {/* End: Connect Box */}

          {/* Connect Box */}
          <div className="wkw-connect-box">
            <h3 className="wkw-connect-box_title">
              Via Email
            </h3>

            <div className="wkw-connect-box_content">
              <p>
                More enquiries about the WKWSCI Programme after Open House?<br />
                Drop us an email at <a href="mailto:ask_wkwsci@ntu.edu.sg"><strong>ask_wkwsci@ntu.edu.sg</strong></a>!
              </p>
              <p>Click on the buttons below for more information:</p>

              {/* Connect Box - Links */}
              <div className="wkw-connect-box_links">
                <a
                  href="https://www.ntu.edu.sg/wkwsci/admissions/programmes"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wkw-connect-box_programme"
                >
                  <span>Information On WKWSCI Programme</span>
                </a>

                <a
                  href="https://www.ntu.edu.sg/wkwsci/admissions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wkw-connect-box_admission"
                >
                  <span>Information On Admissions</span>
                </a>

                <a
                  href="https://www.ntu.edu.sg/admissions/undergraduate/financial-matters/financial-aid"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="wkw-connect-box_enquiries"
                >
                  <span>Information On Financial Aid</span>
                </a>
              </div>
              {/* End: Connect Box - Links */}

            </div>
          </div>
          {/* End: Connect Box */}

        </AnimateHeight>
      </section>
    );
  }

}

export default Connect;
