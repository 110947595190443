import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { Element } from 'react-scroll';

// Assets.

import overviewBanner from '../../assets/images/banner-overview@2x.png';
import requirementsTable from '../../assets/images/overview-requirements-table@2x.png';

const sectionName = 'section-overview';

class Overview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
  }

  handleSectionOnClick = () => {
    const { height } = this.state;

    this.setState({
      height: height === 0 ? 'auto' : 0,
    });

    this.props.handleScrollToElementOnChange(sectionName);
  };

  render() {
    const { overviewHeight } = this.props;

    let sectionOpenedClass = overviewHeight === 0 ? '' : 'section-toggled';

    return (
      <section className={`section-overview ${sectionOpenedClass}`}>
        <Element name={sectionName} className="section-tab">
          <a
            onClick={ () => this.props.handleSectionOnClick(sectionName) }
          >
            Overview
            <span className="section-tab-toggle"></span>
          </a>
        </Element>

        <AnimateHeight
          duration={ 500 }
          height={ overviewHeight }
          easing="ease-out"
          className="section-content"
          contentClassName="section-content-inner"
        >
          {/* Banner */}
          <div className="wkw-banner">
            <img src={overviewBanner} alt="" />
          </div>
          {/* End: Banner */}

          {/* Overview Box */}
          <div className="wkw-overview-box">
            <div className="wkw-overview-box-inner">

              {/* Shadow Box */}
              <div className="wkw-normal-box">
                <div className="wkw-normal-box_title">
                  <h3 className="wkw-normal-box_title_inner">
                    Curriculum Structure
                  </h3>
                </div>

                <div className="wkw-normal-box_content">
                  {/* Row */}
                  <div className="wkw-normal-box_row">

                    {/* Row -> Column */}
                    <div className="wkw-normal-box_col">
                      <div className="wkw-normal-box_col_subtitle">
                        Year 1
                      </div>
                      <div className="wkw-normal-box_col_description">
                        University and Major Foundational Courses
                      </div>
                    </div>
                    {/* End: Row -> Column */}

                    {/* Row -> Column */}
                    <div className="wkw-normal-box_col">
                      <div className="wkw-normal-box_col_subtitle">
                        Year 2
                      </div>
                      <div className="wkw-normal-box_col_description">
                        Electives + Exchange Opportunities
                      </div>
                    </div>
                    {/* End: Row -> Column */}

                    {/* Row -> Column */}
                    <div className="wkw-normal-box_col">
                      <div className="wkw-normal-box_col_subtitle">
                        Year 3
                      </div>
                      <div className="wkw-normal-box_col_description">
                        Electives + Professional Internship
                      </div>
                    </div>
                    {/* End: Row -> Column */}

                    {/* Row -> Column */}
                    <div className="wkw-normal-box_col">
                      <div className="wkw-normal-box_col_subtitle">
                        Year 4
                      </div>
                      <div className="wkw-normal-box_col_description">
                        Electives + Final-Year Project
                      </div>
                    </div>
                    {/* End: Row -> Column */}

                  </div>
                  {/* End: Row */}
                </div>
              </div>
              {/* End: Shadow Box */}

              {/* Shadow Box */}
              <div className="wkw-normal-box">
                <div className="wkw-normal-box_title">
                  <h3 className="wkw-normal-box_title_inner">
                    Academic Requirements
                  </h3>
                </div>

                <div className="wkw-normal-box_content">
                  <div className="wkw-normal-box_image">
                    <img src={requirementsTable} alt="" />
                  </div>
                </div>
              </div>
              {/* End: Shadow Box */}

              {/* Download Box */}
              <div className="wkw-download-box">
                <a
                  href="https://bit.ly/WKWSCIUGBrochure"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Our Brochure
                </a>
              </div>
              {/* End: Download Box */}

              </div>
            </div>
          {/* End: Overview Box */}
        </AnimateHeight>
      </section>
    );
  }

}

export default Overview;
