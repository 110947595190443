import React, { Component } from 'react';
// import ReactModal from 'react-modal';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// Assets.

// import iconRedClock from '../../assets/images/icon-clock-red.png';
// import iconGreenClock from '../../assets/images/icon-clock-green@2x.png';
// import icon1pm from '../../assets/images/icon-1pm@2x.png';
// import icon2pm from '../../assets/images/icon-2pm@2x.png';
// import icon3pm from '../../assets/images/icon-3pm@2x.png';
// import icon4pm from '../../assets/images/icon-4pm@2x.png';

import icon1pm from '../../assets/images/icon-1pm@2x.png';

class LandingPhysical extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleOpenModal = () => {
    this.setState({ isOpen: true });
  }

  handleCloseModal = () => {
    this.setState({ isOpen: false });
  }

  render() {
    return (
      <div className="wkw-landing-content-box_content">

        <div className="wkw-landing-content-box_slider">
          <Carousel
            renderIndicator={false}
            showArrows={true}
            showThumbs={false}
          >

            {/* <div className="wkw-tour-schedule-container">
              <h3>WKWSCI Media Experiential Tours</h3>

              <div className="wkw-tour-schedule">
                <div className="wkw-tour-schedule-icon">
                  <img src={iconGreenClock} alt="" />
                </div>

                <div className="wkw-tour-schedule-icon-content">
                  <div className="wkw-tour-schedule-icon-subtitle">
                    Tour Schedules:
                  </div>
                  <div className="wkw-tour-schedule-icon-description">
                    11am, 12pm, 1pm, 2pm, 3pm, 4pm
                  </div>
                </div>
              </div>

              <div className="wkw-divider"></div>

              <p className="wkw-tour-content">
                Led by our student ambassadors, the 30-min interactive tours will
                give visitors a chance to experience our purpose-built School
                facilities. <i>Each tour group will be kept to a maximum of 5 visitors.</i>
              </p>

              <div className="wkw-tour-btn-wrap">
                <a
                  href="https://smscrm.ntu.edu.sg/en-US/events/event/?id=ef190c45-4f98-46b5-a1f7-c1a1c3c8b986"
                  className="wkw-tour-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign Up Here
                </a>
              </div>

              <div className="wkw-registration-text">
                <i>*Registration for all physical events is compulsory. Please submit an individual registration form for each visitor. Thank you!</i>
              </div>
            </div> */}

            {/* <div className="wkw-ama-sessions-container">
              <h3>WKWSCI AMA Sessions</h3>

              <div className="wkw-ama-sessions">
                <div className="wkw-ama-session">
                  <div className="wkw-ama-session-icon">
                    <img src={icon1pm} alt="" />
                  </div>

                  <div className="wkw-ama-session-icon-content">
                    <div className="wkw-ama-session-icon-description">
                      Strategic Communication for the Digital Age
                    </div>
                  </div>
                </div>

                <div className="wkw-ama-session">
                  <div className="wkw-ama-session-icon">
                    <img src={icon2pm} alt="" />
                  </div>

                  <div className="wkw-ama-session-icon-content">
                    <div className="wkw-ama-session-icon-description">
                      Journalism and Media Policies
                    </div>
                  </div>
                </div>

                <div className="wkw-ama-session">
                  <div className="wkw-ama-session-icon">
                    <img src={icon3pm} alt="" />
                  </div>

                  <div className="wkw-ama-session-icon-content">
                    <div className="wkw-ama-session-icon-description">
                      Data Science and Media Studies
                    </div>
                  </div>
                </div>

                <div className="wkw-ama-session">
                  <div className="wkw-ama-session-icon">
                    <img src={icon4pm} alt="" />
                  </div>

                  <div className="wkw-ama-session-icon-content">
                    <div className="wkw-ama-session-icon-description">
                      Broadcast Media and Cinema Studies
                    </div>
                  </div>
                </div>
              </div>

              <div className="wkw-divider wkw-divider2"></div>

              <p className="wkw-tour-content">
                At WKWSCI, students can pursue courses across different tracks. Hear from our faculty members or ask us anything at any one of our AMA sessions! <i>Each session will be capped at 50 pax.</i>
              </p>

              <div className="wkw-tour-btn-wrap">
                <a
                  href="https://smscrm.ntu.edu.sg/en-US/events/event/?id=ef190c45-4f98-46b5-a1f7-c1a1c3c8b986"
                  className="wkw-tour-btn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign Up Here
                </a>
              </div>

              <div className="wkw-registration-text">
                <i>*Registration for all physical events is compulsory. Please submit an individual registration form for each visitor. Thank you!</i>
              </div>
            </div> */}

            {/* <div className="wkw-ama-sessions-container">
              <h1>WKWSCI Booth</h1>
              <div className="wkw-divider"></div>
              <div className="wkw-tour-content">
                <h5>Hear from our staff and students or ask us anything at the WKWSCI school booth! <span className="text-blue">(Nanyang Auditorium, Level 3)</span></h5>
              </div>
            </div>

            <div className="wkw-ama-sessions-container">
              <h1>Talks</h1>
              <div className="wkw-divider"></div>

              <div className="wkw-ama-sessions">
                <div className="wkw-ama-session">
                  <div className="wkw-ama-session-icon">
                    <img src={icon1pm} alt="" />
                  </div>

                  <div className="wkw-ama-session-icon-content">
                    <div className="wkw-ama-session-icon-description">
                      #WhyWKWSCI - Join <strong>Dr Mark Cenite</strong> and our students to find out more about our Communication Studies programme <span className="text-blue">(Lecture Theatre 1, Level 2 beside North Spine food court)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="wkw-tour-schedule-container">
              <h1>MEDIA EXPERIENTIAL TOUR</h1>
              <h3 className="text-red2 text-upper">10am – 5pm</h3>
              <div className="wkw-divider"></div>
              <div className="wkw-tour-content">
                <p>Experience our purpose-build school facilities – from the communal benches to the cutting-edge media production suites <span className="text-blue">(WKWSCI, Level 1)</span></p>
                <p>Our student guides will be departing from the school booth at Nanyang Auditorium to WKWSCI every half hourly from 10:30am – keep a lookout for them if you wish to visit our campus!</p>
              </div>
            </div> */}

            <div className="wkw-slide-container">
              <div className="wkw-slide-content">
                <h1>WKWSCI Booth</h1>
                <h3 className="text-red2 text-upper">10am – 5pm</h3>
                {/* <div className="wkw-divider"></div> */}
                <div className="wkw-slide-text">
                  <p>Hear from our staff and students or ask us anything at the WKWSCI school booth!<br /><span className="text-blue">(Nanyang Auditorium, Level B1)</span></p>
                </div>
              </div>

              {/* <div className="wkw-slide-content">
                <h1>Talks</h1>
                <div className="wkw-divider"></div>
                <div className="wkw-ama-sessions">
                  <div className="wkw-ama-session">
                    <div className="wkw-ama-session-icon">
                      <img src={icon1pm} alt="" />
                    </div>

                    <div className="wkw-ama-session-icon-content">
                      <div className="wkw-ama-session-icon-description">
                        #WhyWKWSCI - Join <strong>Dr Mark Cenite</strong> and our students to find out more about our Communication Studies programme <span className="text-blue">(Lecture Theatre 1, Level 2 beside North Spine food court)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="wkw-slide-content">
                <h1>Talks</h1>
                <h3 className="text-red2 text-upper">#WhyWKWSCI – The WKWSCI Experience<br />1PM & 3PM @ LT19A</h3>
                {/* <div className="wkw-divider"></div> */}
                <div className="wkw-slide-text">
                  <p>Join <strong>Dr Mark Cenite</strong> and our students to find out more about our Communication Studies programme.</p>
                  <p>Please keep an eye out for our student guides (outside LT19A) if you wish to visit WKWSCI after the talk!</p>
                </div>
              </div>
            </div>

            <div className="wkw-slide-container wkw-tour">
              <div className="wkw-slide-content">
                <h1>MEDIA EXPERIENTIAL TOUR</h1>
                <h3 className="text-red2 text-upper">10am – 5pm</h3>
                <div className="wkw-divider"></div>
                <div className="wkw-slide-text">
                  <p>Head down directly to WKWSCI and experience our purpose-build school facilities – from the communal benches to the cutting-edge media production suites.</p>
                  <p>Our student guides will be bringing visitors from our school booth at Nanyang Auditorium to WKWSCI at the following timings: <strong className="strong2">10:45am, 11:45am</strong>.</p>
                  <p>Stay on the lookout for them if you plan on visiting our campus!</p>
                </div>
              </div>
            </div>

          </Carousel>
        </div>

      </div>
    );
  }

}

export default LandingPhysical;
