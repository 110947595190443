import React, { Component } from 'react';
import { Element } from 'react-scroll';

// Custom components.

import LandingMedia from '../../components/Home/LandingMedia';
import LandingPhysical from '../../components/Home/LandingPhysical';

// Libraries.

import getVideoId from 'get-video-id';

// Assets.

import schoolLogos from '../../assets/images/logo-schools@2x.png';
import iconCenterLeft from '../../assets/images/landing-icon-center-left@2x.png';
import iconCenterRight from '../../assets/images/landing-icon-center-right@2x.png';
import iconCenterTop from '../../assets/images/landing-icon-center-top@2x.png';
import iconRightTop from '../../assets/images/landing-icon-right-top@2x.png';

const sectionName = 'section-home';

class Landing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpened: false,
      currentAction: 'physical',

      data: {},
      media: {
        show_refresh_browser_text: false,
      },
    };
  }

  componentDidMount() {
    // Check local JSON data.
    this.getData();
  }

  getData = () => {
    var epoch = new Date().getTime();

    fetch('landing-box.json?v=' + epoch, {
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    })
    .then((response) => {
      return response.json();
    })
    .then((json) => {
      this.setState({ data: json });
      this.checkData(json);
    });
  }

  checkData = (data) => {
    if (!data.current_url) return;

    const videoObj = getVideoId(data.current_url);

    var mediaState = {};

    if (videoObj.service === 'youtube') {
      /* this.setState({
        media: {
          type: 'youtube',
          id: videoObj.id,
        },
      }); */
      mediaState.type = 'youtube';
      mediaState.id = videoObj.id;
    } else if (videoObj.service === null) {
      // const urlType = this.isAbsoluteOrRelativeUrl(data.current_url);

      // if (!urlType) {
        // Relative URL.

        const isImageUrl = this.isImageUrlFileExtension(data.current_url);
        // console.log('isImageUrl', data.current_url, isImageUrl);
        if (isImageUrl) {
          // Image with relative URL.
          /* this.setState({
            media: {
              type: 'image',
              id: "./banners/" + data.current_url,
              has_facebook_link: data.click_image_goto_facebook,
            },
          }); */

          mediaState.type = 'image';
          mediaState.id = "./banners/" + data.current_url;
          mediaState.has_facebook_link = data.click_image_goto_facebook;
        }
      // }
    }

    if (data.show_refresh_browser_text) {
      /* var newMedia = this.state.media;
      newMedia.show_refresh_browser_text = true;
      this.setState({ media: newMedia }); */

      mediaState.show_refresh_browser_text = true;
    }

    this.setState({ media: mediaState });
  }

  // Reference: https://stackoverflow.com/a/19709846
  isAbsoluteOrRelativeUrl = (url) => {
    var regex = new RegExp('^(?:[a-z]+:)?//', 'i');
    return regex.test(url);
  }

  // Reference: https://stackoverflow.com/a/9714891
  isImageUrlFileExtension = (url) => {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  handleMenuClick = () => {
    const { menuOpened } = this.state;

    this.setState({
      menuOpened: !menuOpened,
    });
  };

  handleMenuItemClick = (sectionName) => {
    const { height, menuOpened } = this.state;

    this.setState({
      height: height === 0 ? 'auto' : 0,
      menuOpened: !menuOpened,
    });

    this.props.handleSectionOnClick(sectionName, true);
  };

  render() {
    const { menuOpened, currentAction, media } = this.state;

    let menuOpenedClass = !menuOpened ? '' : 'active';

    return (
      <section className="section-home">
        <Element name={sectionName}>
        </Element>

        <header className="site-header">
          {/* Logos */}
          <div className="site-logos">
            <img src={schoolLogos} alt="" />
          </div>

          {/* Menu */}
          <div className={`site-menu ${menuOpenedClass}`}>
            <span
              className="toggle-icon"
              onClick={ this.handleMenuClick }
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
        </header>

        {/* Menu Nav */}
        <div className={`site-menu-nav ${menuOpenedClass}`}>
          <a onClick={ () => this.handleMenuItemClick('section-home') }>Home</a>
          <a onClick={ () => this.handleMenuItemClick('section-why') }>Why WKWSCI?</a>
          <a onClick={ () => this.handleMenuItemClick('section-overview') }>Overview</a>
          <a onClick={ () => this.handleMenuItemClick('section-explore') }>Explore</a>
          <a onClick={ () => this.handleMenuItemClick('section-tour') }>Virtual Tour</a>
          <a onClick={ () => this.handleMenuItemClick('section-connect') }>Get Connected</a>
        </div>
        {/* End: Menu Nav */}

        <div className="section-content">

          {/* Landing Box */}
          <div className={"wkw-landing-box " + (currentAction === 'virtual' ? 'virtual' : '')}>

            {/* Bubble */}
            <div className="wkw-landing-bubble1">
              <p className="wkw-flex">
                <span>Join us on 2nd March for the NTU Open House!</span>
              </p>
            </div>
            {/* End: Bubble */}

            {/* Bubble */}
            <div className="wkw-landing-bubble2_1">
              <p className="wkw-flex">
                <span>Scroll here to find out more about our talks, tours and where to find us!</span>
              </p>
            </div>
            {/* End: Bubble */}

            {/* Floating Objects */}
            <div className="section-landing-objects">
              <div className="section-landing-object1">
                <img src={iconCenterLeft} alt="" />
              </div>
              <div className="section-landing-object2">
                <img src={iconCenterRight} alt="" />
              </div>
              <div className="section-landing-object3">
                <img src={iconCenterTop} alt="" />
              </div>
              <div className="section-landing-object4">
                <img src={iconRightTop} alt="" />
              </div>
            </div>
            {/* End: Floating Objects */}

            <div className={`wkw-landing-content-box ${currentAction}`}>
              {currentAction === 'virtual' &&
                <LandingMedia media={media} />
              }

              {currentAction === 'physical' &&
                <LandingPhysical />
              }
            </div>

            {currentAction === 'virtual' &&
            media.show_refresh_browser_text &&
              <div className="wkw-refresh-text text-upper">
                Please refresh your browser for live streams
              </div>
            }

          </div>
          {/* End: Landing Box */}

        </div>
      </section>
    );
  }

}

export default Landing;
