import React, { Component } from 'react';
import ReactModal from 'react-modal';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// Libraries.

import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';

// Assets.

// import comingSoonBanner from '../../assets/images/banner-landing-coming-soon-02.png';
// import upNext1pmBanner from '../../assets/images/banner-landing-1pm.png';
// import upNext3pmBanner from '../../assets/images/banner-landing-3pm.png';
// import endedBanner from '../../assets/images/banner-landing-ended.png';
// import videoBanner from '../../assets/images/banner-video-thumb.png';

import icon1pm from '../../assets/images/icon-1pm@2x.png';
import icon3pm from '../../assets/images/icon-3pm@2x.png';

const plyrOptions = {
  controls: ['play-large', 'play', 'progress', 'current-time', 'mute', null, 'captions', null, 'pip', null, 'fullscreen'],
  youtube: { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 },
  // ratio: '4:3',
  ratio: '16:9',
};

class LandingMedia extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleOpenModal = () => {
    this.setState({ isOpen: true });
  }

  handleCloseModal = () => {
    this.setState({ isOpen: false });
  }

  render() {
    const { media } = this.props;

    return (
      <div className="wkw-landing-content-box_content">

        {media.type == 'image' &&
          media.has_facebook_link &&
          <a href="https://www.youtube.com/WKWschool" target="_blank">
            <img src={media.id} />
          </a>
        }

        {media.type == 'image' &&
          !media.has_facebook_link &&
          <img src={media.id} />
        }

        {media.type == 'youtube' &&
          <div className="wkw-landing-content-box_video">
            <img
              src={`https://i3.ytimg.com/vi/${media.id}/maxresdefault.jpg`}
              alt=""
              onClick={this.handleOpenModal}
            />
          </div>
        }

        {/* Video */}
        {media.type == 'youtube' &&
          <div className="wkw-video-container">
            <Plyr
              source={{
                type: "video",
                sources: [{ src: media.id, provider: "youtube" }],
              }}
              options={plyrOptions}
            />
          </div>
        }

        {/* <div className="wkw-landing-content-box_slider">
          <Carousel
            renderIndicator={false}
            showArrows={true}
            showThumbs={false}
          >

            <div className="wkw-virtual-container">
              <h1>VIRTUAL OPEN HOUSE</h1>
              <h3 className="text-red2 text-upper">11th February</h3>
              <div className="wkw-divider"></div>

              <div className="wkw-ama-sessions-container">
                <h4>Live Talks</h4>

                <div className="wkw-ama-sessions">
                  <div className="wkw-ama-session">
                    <div className="wkw-ama-session-icon">
                      <img src={icon1pm} alt="" />
                    </div>

                    <div className="wkw-ama-session-icon-content">
                      <div className="wkw-ama-session-icon-description">
                        #WhyWKWSCI with <strong>Dr Mark Cenite</strong> <strong className="strong2">(45 mins)</strong>
                      </div>
                    </div>
                  </div>

                  <div className="wkw-ama-session">
                    <div className="wkw-ama-session-icon">
                      <img src={icon3pm} alt="" />
                    </div>

                    <div className="wkw-ama-session-icon-content">
                      <div className="wkw-ama-session-icon-description">
                        #WhyWKWSCI: The WKWSCI Experience with <strong>Assoc Prof Jung Younbo</strong> <strong className="strong2">(60 mins)</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Carousel>
        </div> */}

        <ReactModal
          isOpen={this.state.isOpen}
          contentLabel=""
          style={{
            overlay: {
              zIndex: 99,
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
            },
            content: {
              // display: 'flex',
              // alignItems: 'center',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: 'none',
              border: 'none',
            },
          }}
        >
          <a
            className="react-modal-close"
            onClick={this.handleCloseModal}
          >
            x
          </a>

          <div className="react-modal-video">
            {media.type === 'youtube' &&
              <Plyr
                source={
                  {
                    type: "video",
                    sources: [
                      {
                        src: media.id,
                        provider: "youtube",
                      },
                    ]
                  }
                }
                options={plyrOptions}
              />
            }
          </div>
        </ReactModal>
        {/* <ModalVideo channel='vimeo' isOpen={this.state.isOpen} videoId='202379083' onClose={() => this.setState({isOpen: false})} /> */}
        {/* <button onClick={this.handleOpenModal}>Open</button> */}

      </div>
    );
  }

}

export default LandingMedia;
