import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { Element } from 'react-scroll';

// Assets.

// import tourBanner from '../../assets/images/banner-tour-coming-soon.jpg';
import iconBottomRight from '../../assets/images/tour-icon-bottom-right@2x.png';

const sectionName = 'section-tour';

class Tour extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
  }

  handleSectionOnClick = () => {
    const { height } = this.state;

    this.setState({
      height: height === 0 ? 'auto' : 0,
    });

    this.props.handleScrollToElementOnChange(sectionName);
  };

  render() {
    const { tourHeight } = this.props;

    let sectionOpenedClass = tourHeight === 0 ? '' : 'section-toggled';

    return (
      <section className={`section-tour ${sectionOpenedClass}`}>
        <Element name={sectionName} className="section-tab">
          <a
            onClick={ () => this.props.handleSectionOnClick(sectionName) }
          >
            Virtual Tour
            <span className="section-tab-toggle"></span>
          </a>
        </Element>

        <AnimateHeight
          duration={ 500 }
          height={ tourHeight }
          easing="ease-out"
          className="section-content"
          contentClassName="section-content-inner"
        >
          {/* Tour Box */}
          <div className="wkw-tour-box">
            <div className="wkw-tour-box-inner">

              {/* Video Box */}
              <div className="wkw-video-box">
                <div className="wkw-video">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/rfgwpsDdaVQ?rel=0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Virtual Tour" allowFullScreen></iframe>
                </div>

                <div className="wkw-video-box_description">
                  <p className="text-justify2">
                    The house of the WKWSCI students is a 7,000sq m facility that houses dedicated labs equipped with cutting-edge technology for audio, radio, video, television, print media, multimedia production, advertising as well as communication and information research. With the most technologically advanced equipment and resources at your fingertips, you will be equipped with the skills and expertise needed to excel in an ever-evolving media landscape. Rest assured that you will be graduating ahead of the game.
                  </p>
                </div>
              </div>
              {/* End: Video Box */}

            </div>
          </div>
          {/* End: Tour Box */}

          {/* Floating Objects */}
          <div className="section-tour-objects">
            <div className="section-tour-object1">
              <img src={iconBottomRight} alt="" />
            </div>
          </div>
          {/* End: Floating Objects */}
        </AnimateHeight>
      </section>
    );
  }

}

export default Tour;
